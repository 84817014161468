import styled from "styled-components";

export const Container = styled.div`


    h1{
        margin-left: 3vh;
        margin-top: 2vh;
        font-size: 17px;
    }
    width: 100vw;
    background-color: #000000;
    
    
`

export const ContainerMain01 = styled.div`

    height: 100vh;
    background-color: #DCDCDC;
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .divButtonNotificationGrupo{
        
        display: flex;
        width: 90%;
        height: 220px;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        gap: 5px;

        input{
            width: 20%;
            height: 35px;
        }
        textarea{
            width: 30%;
        }

        p{
            font-size: 13px;
            font-weight: bold;
        }

        button{
            background-color: green;
            border: none;
            width: 200px;
            height: 30px;
            font-size: 14px;
            font-weight: bold;
            color: white;
            border-radius: 10px;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
            &:active{
                opacity: 0.5;
            }
        }
    }
`

export const ContainerMenu = styled.div`

    height: 60px;
    width: 95%;
    background-color: #ffffff;
    margin-top: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;


        button{
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            width: 50px;
            height: 30px;
            font-size: 14px;
            font-weight: bold;
            color: #0000FF;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
            &:active{
                opacity: 0.5;
            }
        }
    
`

export const ContainerTable = styled.div`
    height: 90vh;
    width: 95%;
    background-color: #ffffff;
    margin-top: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    
`