import React from "react";
import {useState} from 'react'
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import apiAgendaNaMao from "../../services/api";
import { toast } from 'react-toastify';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EditIcon from '@mui/icons-material/Edit';
import {
    Container,
    ContainerItens,
    Div,
    ContainerNavModal,
   
    
} from "./styles"

Modal.setAppElement("#root");

export function ModalNotifications(i){

    const [openModalClosed, setOpenModalClosed] = useState(false)
    
    const {
        register,
        handleSubmit
      } = useForm()

    const onSubmit = async data => {
       
         try{



          // window.location.reload()
           closeModal()

        }catch(err){
        }
    }

    //função para quando for aciosado vai abrir a modal
    function openModal(){
        setOpenModalClosed(true)
    }

    //função para quando for aciosado vai fechar a modal
    function closeModal(){
        setOpenModalClosed(false)
    }

    return(
        
        <Container>
            <Div>
                <a onClick={openModal}><NotificationsActiveIcon/></a>
            </Div>

            <Modal
                isOpen={openModalClosed}
                onRequestClose={closeModal}
                contentLabel="exemplo modal"
                overlayClassName="modal-overlay"
                className="modal-content"
                >
                <ContainerItens>
                    
                    <ContainerNavModal>
                        <label id="nameHead">Editar informações</label>
                        <button id="buttonExit" onClick={closeModal}>X</button>
                    </ContainerNavModal>

                    <div className="itens">
                    <form  onSubmit={handleSubmit(onSubmit)} method="put" > 
                        <div>
                            <p>Nome:</p>
                            <input {...register("name")} defaultValue={i.itens.row.name} />
                            <p>Tel:</p>
                            <input {...register("tel")} defaultValue={i.itens.row.tel} />
                            <p>Estabelecimento:</p>
                            <input {...register("estabelecimento")} defaultValue={i.itens.row.estabelecimento} />
                        </div>
                        <div>
                            <p>Status:</p>
                            <select {...register("status")} defaultValue={i.itens.row.status}>
                                <option value={'Ativado'}>Ativado</option>
                                <option value={'Desativado'}>Desativado</option>
                            </select>
                    
                            <p>Plano:</p>
                            <select {...register("plano")} defaultValue={i.itens.row.plano}>
                                <option value={'Teste grátis'}>Teste grátis</option>
                                <option value={'Plano Pro'}>Plano Pro</option>
                                <option value={'Plano Básico'}>Plano Básico</option>
                            </select>
                
                        </div>
                        <div className="divButtonAtualizar">
                            <button type="submit">Atualizar</button>
                        </div>
                        </form>
                    </div>

        
                    

                </ContainerItens>

            </Modal>
            
        </Container>
    )

}