import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ContainerItens = styled.div`

    position: fixed;
    top: 10vh;
    left: 15vw;
    background: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    height: 80%;
    box-shadow: 2px 2px 10px #9999;
    border-radius: 1%;

    .itens{

        form{
            display: grid;
            gap: 20px;
            grid-template-columns: 1fr 1fr;

            select{
                width: 200px;
                height: 25px;
            }

            input{
                width: 200px;
                height: 25px;
            }
            p{
                margin-top: 10px;
                font-weight: bold;
                font-size: 14px;
            }
        }
    }

    .divButtonAtualizar{
        margin-top: 10px;

        button{
            width: 90%;
            height: 24px;
            border-radius: 10px;
            border: 1px solid black;
            background-color: green;
            color: white;
            cursor: pointer;

            &:hover{
                opacity: 0.8;
            }
            &:active{
                opacity: 0.5;
            }
        }
    }
`


export const Div = styled.div`
    
    display: flex;

    button{
        margin-top: 20px;
        padding-left: 4px;
        width: 140px;
        height: 30px;
        font-size: small;
        background-color: #3498db;
        color: #fff;
        border: none;
        border-radius: 5px;
        box-shadow: 1px 1px 1px #202020;
        font-weight: bolder;
        cursor: pointer;

        &:hover{
            opacity: 0.8;
        }

        &:active{
            opacity: 0.6;
        }

    }
    
`


export const Error = styled.p `

    color: red;
    font-size: 10px;

`

export const ContainerNavModal = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: #696969;
  display: flex;
  justify-content: space-between;
  align-items: center;

    #nameHead{
        color: #ffffff;
        margin-left: 10px;
        font-size: 12px;
        font-weight: normal;
    }

    #buttonExit{
        font-size: 18px;
        margin-top: 2px;
        cursor: pointer;
        color: #ffffff;
        border: none;
        font-weight: bold;
        background: transparent;
        width: 20px;
        border: none;
        margin-right: 5px;

        &:hover{
            opacity: 0.8;
            background: red;
            color: #fff;
        }

        &:active{
            opacity: 0.6;  
        }
    }
`




