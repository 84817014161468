import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiAgendaNaMao from "../../services/api";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import moment from "moment-timezone";
import { useForm } from "react-hook-form"
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import imgWhats from '../../assets/whatsapp.png'
import insta from '../../assets/Instagram_icon.png'
import face from '../../assets/facebook png.png'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DataScroller } from 'primereact/datascroller';
import CircularProgress from '@mui/material/CircularProgress';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import swal from 'sweetalert';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventIcon from '@mui/icons-material/Event';
import SearchIcon from '@mui/icons-material/Search';
import { v4 } from "uuid";
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import util from "../../util";

import {
    Container,
    ButtonAgendamentos,
    SectionPhoto,
    SectionService,
    SectionColl,
    SectionDay,
    SectionHours,
    SectionComanda,
    SectionInforCliente,
    SectionMyAgendamentos,
    ContainerItens,
    SectionMes,
} from './styles'


export function Agendamentos() {
    const { id } = useParams([])
    const [services, setServices] = useState([])
    const [Usuarios, setUsuarios] = useState([])
    const [Collaborators, setCollaborators] = useState([])
    const [ListNotifications, setListNotifications] = useState([])
    const [FilterListNotifications, setFilterListNotifications] = useState([])
    const [FilterColls, setFilterColls] = useState([])
    const [diasDisponiveis, setDiasDisponiveis] = useState([])
    const [mesDisponiveis, setMesDisponiveis] = useState([])
    const [FilterDiaEscolhido, setFilterDiaEscolhido] = useState([])
    const [servicesFilter, setServicesFilter] = useState([])
    const [SlotHours, setSlotHours] = useState([])
    const [NameColls, setNameColls] = useState([])
    const [ListAgendamentos, setListAgendamentos] = useState([])

    //Estados de informações para colocar na comanda.
    const [DataSelect, setDataSelect] = useState([])
    const [NameCollSelect, setNameCollSelect] = useState([])
    const [NameServiceSelect, setNameServiceSelect] = useState([])
    const [PriceServiceSelect, setPriceServiceSelect] = useState([])
    const [TimeServiceSelect, setTimeServiceSelect] = useState([])

    //Estados de informações para envio das informações para o banco.
    const [Service_id, setService_id] = useState([])
    const [Collaborators_id, setCollaborators_id] = useState([])
    const [DaySelect, setDaySelect] = useState([])
    const [HourSelect, setHourSelect] = useState([])

    const [screm, setScrem] = useState('Service')
    const [progress, setProgress] = React.useState(0);
    const [ScremProgress, setScremProgress] = useState('ativado')
    const [ScremOutraDate, setScremOutraDate] = useState('desativado')
    const [ScremDataUser, setScremDataUser] = useState('ativado')
    const [SearchService, setSearchService] = useState('')
    const [NewsServiceSearch, setNewsServiceSearch] = useState([])
    const [Carrinho, setCarrinho] = useState([{}])
    const [NewCarrinho, setNewCarrinho] = useState([{}])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()


    useEffect(() => {

        async function loadRecs() {
            const { data: service } = await apiAgendaNaMao.get('service')
            setServices(service)

            const { data: collaborators } = await apiAgendaNaMao.get('collaborator')
            setCollaborators(collaborators)

            const { data: usuarios } = await apiAgendaNaMao.get(`usuarios/${id}`)
            setUsuarios(usuarios)
            setScremDataUser('desativado')

            const { data: listNotification } = await apiAgendaNaMao.get('/push/list_public_key')
            setListNotifications(listNotification)

        }
        loadRecs()

    }, [])



    useEffect(() => {

        const filterService = services.filter(item => item.UserId === id && item.active === 'Sim')
        setServicesFilter(filterService)
        itemTemplate(filterService)

        const filterColls = Collaborators.filter(item => item.UserId === id)
        setFilterColls(filterColls)

        const filterNotifications = ListNotifications.filter(item => item.CollaboratorsId === Collaborators_id)
        setFilterListNotifications(filterNotifications)

    }, [services, Collaborators, id, ListNotifications, Collaborators_id, SearchService])


    const cores = [
        "#6A5ACD",
        "#836FFF",
        "#0890ff",
        "#f4a131",
        "#800000",
        "#CD5C5C",
        "#FF7F50",
        "#FF6347",
        "#FF8C00",
        "#DDA0DD",
        "#DA70D6",
        "#9370DB",
        "#8A2BE2",
        "#8B008B",
        "#006400",
        "#008000",
        "#6B8E23",
        "#808000",
        "#556B2F",
        "#20B2AA",
        "#008B8B",
        "#4682B4",
        "#B8860B",
        "#A52A2A",
        "#696969",
        "#778899",
        "#2F4F4F",
        "#A0522D",
        "#9f9b39",
        "#0087a8",
        "#00cb71",
        "#3c578e",
        "#1e3150",
        "#574116"
    ]

    const colors = cores[Math.ceil(Math.random() * (cores.length - 1))];

    const onSubmit = async (data) => {
        const formatTel = data.tel
        const tel = formatTel.replace("(","").replace(")","").replace("-","")
        const formatCarrinho = NewCarrinho.map(i => ({
            name: data.name,
            tel: tel,
            ServicesId: i.ServicesId,
            CollaboratorsId: i.CollaboratorsId,
            date: new Date(`${i.dia}T${i.hora}`),
            days: i.day,
            UserId: i.UserId,
            color: colors,
            hora: HourSelect,
            tipo_pagamento: 'Não escolhido',
            subscription: i.subscription,
            valor_total: i.priceService
        }))

        const formatEnvioNotfication = NewCarrinho.map(i => ({
            subscription: i.subscription,
            CollaboratorsId: i.CollaboratorsId,
            nameCliente: data.name,
            dataAgendamento: new Date(`${i.dia}T${i.hora}`),
            hora: i.hora,
        }))

        const agendamento = {
            name: data.name,
            tel: data.tel,
            ServicesId: Service_id,
            CollaboratorsId: Collaborators_id,
            date: new Date(`${DataSelect}T${HourSelect}`),
            days: DaySelect,
            UserId: id,
            color: colors
        }

        try {

            for (let i = 0; i < formatCarrinho.length; i++) {
                await toast.promise(
                    apiAgendaNaMao.post('agendamento', formatCarrinho[i]), {
                    pending: 'Verificando seus dados',
                    success: 'Agendamento realizado com sucesso!',
                },)
            }

            swal({
                text: `Agendamento realizado com Sucesso! \n  
                Colaborador: ${NewCarrinho.map(i => i.nameCol)} \n 
                Dia: ${NewCarrinho.map(i => moment(i.dia).format('DD'))} \n 
                Mês: ${NewCarrinho.map(i => moment(i.dia).format('MMM'))} \n 
                Hora: ${NewCarrinho.map(i => i.hora)}`,

                buttons: {
                    text: "Voltar"
                }

            }).then(() => {
                window.location.reload()
            })

            try {
                await apiAgendaNaMao.post('/push/send', {
                    CollaboratorsId: Collaborators_id,
                    subscription: FilterListNotifications,
                    nameCliente: agendamento.name,
                    dataAgendamento: `${moment(DataSelect).format('DD/MM/YYYY')}`,
                    hora: `${HourSelect}`,
                })
            } catch (err) { }

            return

        } catch (err) {
            const response = (err.response.data.error)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }



    //função para solicitar ao backend os dias disponíveis
    async function loadDiasDispo(selectService) {
        let utcAtualUser = moment.tz.guess()

        try {
            const { data: diasDisponiveis } = await apiAgendaNaMao.post('diasdisponiveis', {
                date: moment().format('YYYY-MM-DD'),
                service_id: selectService,
                userId: id,
                utcUser: utcAtualUser
            })

            setScremProgress('desativado')
            setDiasDisponiveis(diasDisponiveis.agenda)
            setScremOutraDate('ativado')

        } catch (err) {
            alert('Error loading available days')
        }
    }

    //função para solicitar ao backend os dias Mesponíveis
    async function loadMesDispo(selectService) {
        let utcAtualUser = moment.tz.guess()
        try {

            const { data: mesDisponiveis } = await apiAgendaNaMao.post('mesdisponiveis', {
                date: moment().tz("America/Sao_Paulo").format('YYYY-MM-DD'),
                service_id: selectService,
                userId: id,
                utcUser: utcAtualUser
            })

            setScremProgress('desativado')
            setMesDisponiveis(mesDisponiveis.agenda)

        } catch (err) {
            alert('Error loading available days')
        }
    }

    //função para pegar o id do serviço selecionado e enviar para requisitar ao backend
    function selectService(item) {

        const idService = item

        servicesFilter.filter((service) => {
            if (service.id === idService) {
                let nameService = service.name
                let priceService = service.price
                let timeService = service.time

                return setNameServiceSelect(nameService), setPriceServiceSelect(priceService), setTimeServiceSelect(timeService)
            }
        })

        setService_id(idService)
        loadDiasDispo(idService)
        loadMesDispo(idService)
    }


    //formatando os dados para colocar os dias disponiveis
    const formatDateDiasDispo = diasDisponiveis.map((item, index, array) => ({
        ano: moment(Object.keys(item)[0]).format('DD-MM-YYYY'),
        mes: moment(Object.keys(item)[0]).format('MMMM'),
        dia: moment(Object.keys(item)[0]).format('dddd'),
        data: moment(Object.keys(item)[0]).format('DD'),
        date: index,
        array: item,
        id: Object.keys(item)
    }))

    const formatDateMesDispo = mesDisponiveis.map((item, index, array) => ({
        ano: moment(Object.keys(item)[0]).format('DD-MM-YYYY'),
        mes: moment(Object.keys(item)[0]).format('MMMM'),
        dia: moment(Object.keys(item)[0]).format('dddd'),
        data: moment(Object.keys(item)[0]).format('DD'),
        date: index,
        array: item,
        id: Object.keys(item)
    }))

    const selectDia = (data) => {

        //Data escolhida pelo cliente
        const dataSelecionada = parseInt(data)

        const diasDisp = formatDateDiasDispo.map(item => item.array)

        const filter = diasDisp.filter((index, i) => {
            return i === dataSelecionada
        })


        setFilterDiaEscolhido(filter)

        const dataSelec = filter.map(item => Object.keys(item)).toString()
        setDataSelect(dataSelec)


        const formatData = parseInt(moment(dataSelec).format('d'))
        setDaySelect(formatData)

        //Pegando só os colaboradores de acordo com a data selecionada
        const colls = filter.map(item => Object.values(item).map(key => Object.keys(key))[0]).flat()


        //Filtrando colaboradores de dias disponiveis e colaboradores cadastrados
        const filtrandoCollaborator = FilterColls.filter(coll => colls.some(i => i === coll.id))
        setNameColls(filtrandoCollaborator)

        /* const format = Object.values(filter[0]).map( item => Object.values(item)[0]).flat()
        setSlotHours(format) */
    }

    //Aqui é para pegar quando cliente clicar em selecionar outra data
    const selectOutroDia = (data) => {

        //Data escolhida pelo cliente
        const dataSelecionada = parseInt(data)

        const diasDisp = formatDateMesDispo.map(item => item.array)

        const filter = diasDisp.filter((index, i) => {
            return i === dataSelecionada
        })


        setFilterDiaEscolhido(filter)

        const dataSelec = filter.map(item => Object.keys(item)).toString()
        setDataSelect(dataSelec)


        const formatData = parseInt(moment(dataSelec).format('d'))
        setDaySelect(formatData)

        //Pegando só os colaboradores de acordo com a data selecionada
        const colls = filter.map(item => Object.values(item).map(key => Object.keys(key))[0]).flat()


        //Filtrando colaboradores de dias disponiveis e colaboradores cadastrados
        const filtrandoCollaborator = FilterColls.filter(coll => colls.some(i => i === coll.id))
        setNameColls(filtrandoCollaborator)

        /* const format = Object.values(filter[0]).map( item => Object.values(item)[0]).flat()
        setSlotHours(format) */
    }

    const selectColl = (item, idColl) => {

        const collSelecionado = item

        setNameCollSelect(collSelecionado)

        const collSelecionadoId = idColl

        setCollaborators_id(collSelecionadoId)

        //Pegando horarios dos servicos adicionado no carrinho
        const ListHoursCarrinho = Carrinho.filter(i => i.dia === DataSelect && i.CollaboratorsId === collSelecionadoId)

        //Convertendo tempo do servico para minutos
        const timeService = util.hourToMinutes(moment(TimeServiceSelect).format('HH:mm'))

        const newListHoursCarrinho = []
        const cortes = 100

        //For para dividir os arrays um por um 
        for (let i = 0; i < ListHoursCarrinho.length; i = i + cortes) {
            newListHoursCarrinho.push(ListHoursCarrinho.map(i => util.sliceMinutes(i.data, moment(i.data).add(timeService, 'minutes'), Usuarios.intervalo_agendamento)).flat().slice(i, i + cortes))
        }

        //Pegando todos os horarios disponiveis e dando flat para separar array
        const hoursDispColls = FilterDiaEscolhido.map(items => Object.values(items)[0][collSelecionadoId]).flat()

        //Colocando todos arrays em apenas um array
        const newA = hoursDispColls.reduce((list, sub) => list.concat(sub), [])

        //Colocando todos arrays em apenas um array da lista do carrinho
        const newB = newListHoursCarrinho.reduce((list, sub) => list.concat(sub), [])

        //Excluindo horarios iguais ao dia e colaborador 
        const newListHours = newA.filter(a => !newB.includes(a))

        if (DataSelect === moment().format('YYYY-MM-DD')) {
            const newHours = newListHours.filter((i) => i >= moment().format('HH:mm'))

            setSlotHours(newHours)
        } else {
            setSlotHours(newListHours)
        }
    }

    //ordenando os horarios em ordem correta
    function formatYearMonthPad(dateStr) {
        return dateStr.split(',')
            .reverse()
            .map((part) => part.padStart(2,'0'))
            .join();
    }

    SlotHours.sort(function (a, b) {
        const aa = formatYearMonthPad(a);
        const bb = formatYearMonthPad(b);
        return aa < bb ? -1 : (aa > bb ? 1 : 0);
    });

    useEffect(() => {
        const newCarrinho = Carrinho.map(i => i)
        newCarrinho.splice(0, 1)
        setNewCarrinho(newCarrinho)

    }, [Carrinho])

    function hourSelect(infor) {

        const horaSelecionada = infor
        setHourSelect(horaSelecionada)

        const selectOutroService = {
            id: v4(),
            ServicesId: Service_id,
            hora: horaSelecionada,
            dia: DataSelect,
            CollaboratorsId: Collaborators_id,
            UserId: id,
            day: DaySelect,
            nameCol: NameCollSelect,
            nameService: NameServiceSelect,
            priceService: PriceServiceSelect,
            timeService: TimeServiceSelect,
            data: moment(`${DataSelect}T${horaSelecionada}`),
            subscription: FilterListNotifications,
        }

        setCarrinho([...Carrinho, selectOutroService])
    }

    const SubmitBuscarAgendamentos = async (data) => {

        const formatTel = data

        try {
            const { data } = await apiAgendaNaMao.get(`/agendamento`)

            const filterAgendamentos = data.filter((item) => item.tel === formatTel.tel && item.UserId === id)
            setListAgendamentos(filterAgendamentos)

        } catch (err) {
            alert('Error ao carregar os Agendamentos')
        }
    }

    //Excluir comanda
    async function deleteAgendamento(item) {

        if (window.confirm('Tem certeza que deseja excluir o Agendamento?')) {
            await apiAgendaNaMao.delete(`/agendamento/${item.id}`)
            window.location.reload()
        }

    }

    function searchService(event) {
        const servicePesquisa = event.target.value
        setSearchService(servicePesquisa)
    }

    function search() {
        const attSearchService = servicesFilter.filter(servi => servi.name == SearchService)
        setNewsServiceSearch(attSearchService)
    }


    //Lista de services
    const itemTemplate = (servicesFilter) => {

        return (
            <div onClick={() => {
                selectService(servicesFilter.id)
                setScrem('Date')
            }} value={servicesFilter.id} className="divServices" key={servicesFilter.id} >

                <div id="divServiceData">
                    <p></p>
                </div>

                <div id="divServiceInfor">
                    <p id="nameSercice">{servicesFilter.name}</p>
                    <p id="timeService"><AccessTimeIcon style={{ width: '15px', marginRight: '4px', color: 'orange' }} /> {moment(servicesFilter.time).format('HH')}h {moment(servicesFilter.time).format('mm')}min</p>
                    <p id="price"><AttachMoneyIcon style={{ width: '15px', color: 'green' }} /> R${servicesFilter.price}</p>
                </div>

                <div id="divIconsSeta">
                    <KeyboardArrowRightIcon style={{ color: 'orange', marginRight: '10px' }} />
                </div>

            </div>
        );
    };

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress === 100 ? 10 : prevProgress + 10));
        }, 200);

        return () => {
            clearInterval(timer);
        };
    }, []);


    function deleteServiceCarrinho(item) {
        const NewCarrinhoAtt = NewCarrinho.filter(i => i.id != item)
        const AttCarrinho = Carrinho.filter(i => i.id != item)
        setNewCarrinho(NewCarrinhoAtt)
        setCarrinho(AttCarrinho)
    }

    if (Usuarios.status === 'Desativado') {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <h2>Conta desativada</h2>
                <p>Para mais informações, <br /> entre em contato com o suporte</p>
            </div>

        )
    }

    const handlePhone = (event) => {
        let input = event.target
        input.value = phoneMask(input.value)
    }

    const phoneMask = (value) => {
        if (!value) return ""
        value = value.replace(/\D/g,'')
        value = value.replace(/(\d{2})(\d)/, "($1)$2")
        value = value.replace(/(\d)(\d{4})$/, "$1-$2")
        return value
    }


    return (
        <Container>
            <div id="DivMyAgendamentos">
                <p>Meus agendamentos</p>
                <ButtonAgendamentos onClick={() => setScrem('meusAgendamentos')}><EventIcon style={{ color: 'orange' }} /></ButtonAgendamentos>
            </div>


            <SectionPhoto>
                <Avatar style={{ width: '120px', height: '120px', marginBottom: '5px', marginTop: '20px' }} alt="foto perfil" src={Usuarios.path} />
                <h2>{Usuarios.estabelecimento}</h2>
                <p>{Usuarios.rua}, {Usuarios.numero} - {Usuarios.bairro}</p>
                <p></p>
                <div id="divRedeSocial">
                    {Usuarios.instagram ? <a rel="noreferrer" target="_blank" href={Usuarios.instagram}><img alt="instagram" style={{ width: '23px' }} src={insta} /></a> : ''}
                    {Usuarios.facebook ? <a rel="noreferrer" target="_blank" href={Usuarios.facebook}><img alt="facebook" style={{ width: '24px' }} src={face} /></a> : ''}
                </div>
                {ScremDataUser === 'ativado' && (
                    <Stack spacing={2} direction="row">
                        <CircularProgress variant="determinate" value={progress} />
                    </Stack>
                )}
            </SectionPhoto>

            <ContainerItens>
                {screm === 'Service' && (
                    <>
                        <h3>Escolha uma opção de Serviço</h3>
                        <div id="divCaixaPesquisa">
                            <input onChange={searchService} type="search" placeholder="Pesquisar Serviço..." /><button onClick={search}><SearchIcon /></button>
                        </div>
                        <SectionService>
                            <DataScroller value={NewsServiceSearch.length > 0 ? NewsServiceSearch : servicesFilter} itemTemplate={itemTemplate} rows={8} inline scrollHeight="600px" />
                        </SectionService>

                    </>
                )}

                {screm === 'Date' && (

                    <div id="divSection">
                        <div id="divItensVoltar">
                            <h3>Escolha uma opção de Data</h3>
                            <button id="BackForm" onClick={() => [setScrem('Service'), setScremProgress('ativado'), setScremOutraDate('desativado')]} > <UndoIcon /> Voltar</button>
                        </div>

                        <SectionDay>
                            {formatDateDiasDispo && formatDateDiasDispo.map(item => (
                                <button id="slotDiasDisp" onClick={() => {
                                    selectDia(item.date)
                                    setScrem('collaborator')
                                }} value={item.date} key={item.id} >
                                    {item.dia}<br />{item.data}<br />{item.mes}
                                </button>
                            ))}

                        </SectionDay>
                        {ScremOutraDate === 'ativado' && (
                            <button id="buttonOutraData" onClick={() => setScrem('DataMes')}><CalendarMonthIcon style={{ color: 'blue', marginRight: '3px' }} /> Escolher outra Data</button>
                        )}
                        {ScremProgress === 'ativado' && (
                            <Stack spacing={2} direction="row">
                                <CircularProgress variant="determinate" value={progress} />
                            </Stack>
                        )}
                    </div>
                )}

                {screm === 'DataMes' && (

                    <div id="divSection">
                        <div id="divItensVoltar">
                            <h3>Escolha outra opção de Data</h3>
                            <button id="BackForm" onClick={() => setScrem('Date')} > <UndoIcon /> Voltar</button>
                        </div>
                        <SectionMes>
                            {formatDateMesDispo && formatDateMesDispo.map(item => (
                                <button id="slotMesDisp" onClick={() => {
                                    selectOutroDia(item.date)
                                    setScrem('collaborator')
                                }} value={item.date} key={item.id} >
                                    <CalendarMonthIcon style={{ color: 'black', marginRight: '6px' }} /> {item.dia}<br />{item.data} - {item.mes}
                                </button>
                            ))}
                        </SectionMes>
                    </div>

                )}

                {screm === 'collaborator' && (
                    <div id="divSection">
                        <div id="divItensVoltar">
                            <h3>Escolha uma opção de Colaborador</h3>
                            <button id="BackForm" onClick={() => setScrem('Date')} ><UndoIcon /> Voltar</button>
                        </div>
                        <SectionColl>
                            <div className="divColl">
                                <Stack direction="row" spacing={1}>
                                    {NameColls && NameColls.map(item => (
                                        <button id="buttonColl" onClick={() => {
                                            selectColl(item.name, item.id)
                                            setScrem('hours')
                                        }} key={item.id}>
                                            <Avatar style={{ width: '90px', height: '90px' }} alt="Foto perfil" src={item.path} />
                                            <br /><p>{item.name}</p>
                                        </button>
                                    ))}
                                </Stack>
                            </div>
                        </SectionColl>
                    </div>
                )}


                {screm === 'hours' && (
                    <div id="divSection">
                        <div id="divItensVoltar">
                            <h3>Escolha uma opção de horários?</h3>
                            <button id="BackForm" onClick={() => setScrem('collaborator')} ><UndoIcon /> Voltar</button>
                        </div>
                        <SectionHours>

                            {SlotHours.length ? '' :
                                <div id="divSemHorarios">
                                    <button style={{ border: 'none', background: 'transparent' }} onClick={() => setScrem('Date')}>
                                        <p>Sem horários disponíveis. Escolha outra Data.</p>
                                        <CalendarMonthIcon /></button>
                                </div>
                            }


                            {SlotHours && SlotHours.map(item => (
                                <div id="divHours" key={item}>
                                    <button id="buttonSlotoHours" onClick={() => { hourSelect(item); setScrem('Comanda') }} value={item}>{item}</button>
                                </div>

                            ))}

                        </SectionHours>
                    </div>
                )}

                {screm === 'Comanda' && (
                    <div id="divSection">

                        <SectionComanda>
                            <h3>Resumo</h3>
                            {NewCarrinho && NewCarrinho.map(i => (
                                <div key={i.id} id="divComanda">

                                    <div id="divMesDiaHora">
                                        <h2>{moment(i.dia).format('MMM')}</h2>
                                        <h4>{moment(i.dia).format('DD')}</h4>
                                        <h5>{i.hora}</h5>
                                    </div>

                                    <div id="divServiceNamePrice">
                                        <p>{i.nameCol}</p>
                                        <p>{i.nameService}</p>
                                        <p>R${i.priceService}</p>
                                    </div>

                                    <button onClick={() => deleteServiceCarrinho(i.id)} ><DeleteIcon style={{ color: 'red' }} /></button>
                                </div>
                            ))}
                            <div id="divMaisService">
                                <button onClick={() => { setScrem('Service') }}><PlaylistAddCircleIcon /> Adicionar mais Serviço.</button>
                            </div>
                        </SectionComanda>

                        <SectionInforCliente>
                            <h5>Confirme seu Agendamento</h5><br />
                            <form onSubmit={handleSubmit(onSubmit)} id="formulario">
                                <label>Nome</label>
                                <input {...register("name", { required: true })} id="name" placeholder="Seu nome" />
                                {errors.name && <span>Campo Obrigatório</span>}

                                <label>Telefone</label>
                                <input maxLength={15} type="tel" onKeyUp={(event) => handlePhone(event)} {...register("tel", { required: true })} id="tel" placeholder="DD XXXXX-XXXX" />
                                {errors.tel && <span>Campo Obrigatório</span>}

                                <button id="ConfirmeAgendamento" type="submit">Confirmar Agendamento</button>
                            </form>
                        </SectionInforCliente>
                    </div>
                )}

                {screm === 'meusAgendamentos' && (
                    <div id="divSection">
                        <SectionMyAgendamentos>
                            <h3>Meus Agendamentos</h3>
                            <div id="divFilterAgendamentos">
                                <p>Por favor, Digite o número do celular(WhatsApp) usado para
                                    agendar o serviço.
                                </p>
                                <form onSubmit={handleSubmit(SubmitBuscarAgendamentos)} >
                                    <input  {...register("tel", { required: true })} name="tel" type="tel" placeholder="Tel" />
                                    <div id="divButtons">
                                        <button onClick={() => setScrem('Service')} >Voltar</button>
                                        <button type="submit" >Enviar</button>
                                    </div>
                                </form>

                            </div>

                            {ListAgendamentos && ListAgendamentos.map(item => (
                                <div id="listAgendamentos" key={item.id}>
                                    <p>Colaborador: {item.Collaborator.name}</p>
                                    <p>Serviço: {item.service.name}, Preço: R${item.service.price}</p>
                                    <p>Data: {moment(item.date).tz("America/Sao_Paulo").format('DD-MM-YYYY')}</p>
                                    <p>Hora: {moment(item.date).tz("America/Sao_Paulo").format('HH:mm')}</p>
                                    <p>Status: {item.status}</p>
                                    <p style={{ display: 'flex', alignItems: 'center' }}>Excluir Agendamento
                                        <button disabled={item.status === "Concluído"} onClick={() => deleteAgendamento(item)} ><DeleteForeverIcon /></button>
                                    </p>
                                </div>
                            ))}
                        </SectionMyAgendamentos>

                    </div>
                )}

            </ContainerItens>
            <a rel="noreferrer" target="_blank" href={`https://wa.me//55${Usuarios.tel}?text=Olá,%20gostaria de mais informações.`} id="linkImgWhats"><img alt="whatsApp" id="imgWhats" src={imgWhats} /></a>

        </Container>
    )


}

