import React, {useState,useEffect} from "react";
import listLinks from "./menu-list";
import { useLocation } from "react-router-dom";
import { Container,} from "./styles";
import PsychologyIcon from '@mui/icons-material/Psychology';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Header } from "../Header";
import paths from '../../constants/paths'
import { useUser } from "../../hooks/UserContext";
const alturaTela = window.innerHeight;

export function SlideMenuAdmin(){
 
    const location = useLocation()
    const {userData} = useUser({})  
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [AlturaTelas, setAlturaTelas] = useState([90])
    const open = Boolean(anchorEl);
    
    useEffect(()=>{

      if(alturaTela <= 667){
        setAlturaTelas(88)
      }

      if(alturaTela <= 667){
        setAlturaTelas(88)
      }
      
    },[])

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return(

      <Container>
       
        <div id="divMenu">
              
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            >
            <MoreVertIcon  style={{color: 'white', }}/>
          </IconButton>
          <div id="divLogout">
            <Header />
          </div>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                  maxHeight: AlturaTelas * 6.5,
                  width: '25ch',
                  marginTop: '1ch',
                  overflow: 'scroll'
                },
            }}
          >
          {userData.name_url === 94308921 ? 
            <MenuItem  
              style={{
                fontSize: '14px',
              }} 
              key={'001'} 
              selected={location.pathname === '/gestaoSistema'} 
              onClick={handleClose}
            >
              <a 
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  gap: '10px',
                  color: 'black',
                  marginTop: '2px',
                  width: '100vw'
                }} id="link" href={'/gestaoSistema'}>{<PsychologyIcon />}{'Gestao Sistema'}
              </a>
      
          </MenuItem>
            : ''
          }
            
          {listLinks.map((option) => (
            <MenuItem  
              style={{
                fontSize: '14px',
              }} 
              key={option.id} 
              selected={location.pathname === option.link} 
              onClick={handleClose}
              >
              <a 
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  gap: '10px',
                  color: 'black',
                  marginTop: '2px',
                  width: '100vw'
                }} id="link" href={option.link}>{option.icon}{option.label}
              </a>
        
            </MenuItem>
          ))}
                    
          </Menu>
        </div>
            
      </Container>
        
        
    )
}
